.sideMenu {
  background-color: var(--background-color);
  width: 15%;
  position: relative;
}
.sideMenuList {
  width: 100%;
  height: auto;
  position: sticky;
  top: 100px;
}
.sideMenuList .row {
  width: 100%;
  height: 50px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--sidemenu-font-color);
  padding-left: 10px;
  cursor: pointer;
  font-size: 14px;
  gap: 10px;
}
.sideMenuList .row:hover {
  background-color: var(--side-menu-hover);
}
