.collapsible {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 1rem;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
}

.collapsible__header {
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  color: var(--text-color);
  font-size: 1.1rem;
  font-weight: 600;
}

.collapsible__content {
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-top: none;
  will-change: height;
}

.collapsible__inner {
  color: var(--text-color);
  border-top: none;
}
