.show-more {
  color: var(--text-color);
  background-color: var(--background-color);
  text-align: left;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 0 16px;
  width: 100%;
  height: 48px;
  transition: all 0.2s ease-in-out;
  gap: 12px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.show-more__progress {
  display: flex;
  align-items: center;
  min-width: 80px;
  justify-content: flex-end;
}

.show-more__progress-text {
  color: var(--secondary-text);
  font-size: 13px;
  margin: 0;
}

/* New styles for the icons */
.show-more__icon {
  color: var(--secondary-text);
  transition: all 0.2s ease-in-out;
  width: 16px;
  height: 16px;
}

.show-more__icon--active {
  color: var(--button-bg);
  filter: drop-shadow(
    0 0 8px rgba(0, 0, 0, 0.2)
  );
}

.show-more:hover .show-more__icon:not(.show-more__icon--active) {
  color: var(--text-color);
}

.show-more__title {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  flex-grow: 1;
  color: var(--text-color);
}
