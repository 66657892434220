.container {
  border-radius: 10px;
  border-color: var(--ProtocolPage-border-color);
  border-style: solid;
  border-width: var(--ProtocolPage-border-size);
  height: 10rem;
  padding: 1rem;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.alertContainer {
  padding: 10px;
  border-radius: 10px;
  margin-top: 2rem;
  align-items: center;
  vertical-align: middle;
  display: flex;
  background-color: rgba(75, 192, 192, 0.2);
}

.alertContainer p {
  margin-left: 10px;
}

.icon {
  color: rgba(75, 192, 192, 1);
  align-items: center;
  display: flex;
}
