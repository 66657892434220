.confirmation-code-step {
  max-width: 320px;
  margin: 0 auto;
  color: #ffffff;
  background-color: var(--container-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow:
    0 2px 10px rgba(0, 0, 0, 0.1),
    0 0 1px rgba(255, 255, 255, 0.1);
  padding: 32px;
}

.confirmation-code-step__title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 12px;
  text-align: left;
  color: #ffffff;
}

.confirmation-code-step__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.confirmation-code-step__input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.confirmation-code-step__label {
  font-size: 14px;
  color: #a0a0b0;
}

.confirmation-code-step__input {
  width: 100%;
  padding: 12px 16px;
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: 5px;
  color: var(--input-text);
  font-size: 14px;
}

.confirmation-code-step__input::placeholder {
  color: var(--placeholder-color);
}

.confirmation-code-step__error-msg {
  color: #ff6b6b;
  font-size: 12px;
}

.confirmation-code-step__buttons {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.confirmation-code-step__progress-bar {
  margin-top: 24px;
  margin-bottom: 16px;
}
