.button {
  width: 38px;
  height: 38px;
  background-color: var(--backgound-color-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}

.innerBox {
  width: 75%;
  height: 75%;
  border: 1px solid var(--lightborder-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerBox svg {
  color: var(--lightborder-color);
  transform: scale(1.24);
}
