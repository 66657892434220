/* Footer styles */

.footer {
  background: var(--backgound-color-dark);
  color: var(--font-color);
  padding: 30px 0;
}

.socialAndLightContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.container {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto;
}

.footer .infoLinks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer a {
  text-decoration: none;
  color: var(--font-color);
  transition: all 0.3s ease-in-out;
}

.footer a:hover {
  color: #6e5ee2;
}

.footer .copyrightInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer .socialLinks > * {
  margin: 0 7px;
}
