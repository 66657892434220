.container {
  border-radius: 10px;
  border-color: var(--ProtocolPage-border-color);
  border-style: solid;
  border-width: var(--ProtocolPage-border-size);
  min-height: 10rem;
  padding: 1rem;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.alertContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.InfoContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  white-space: nowrap;
}

.InfoContainer p {
  margin-top: 10px;
  color: var(--sidemenu-font-color);
}

.chartContainer {
  width: 30%;
  height: 15rem;
}

.chartContainerFull {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
}

.vulReportContainer {
  display: flex;
  flex-direction: column;
  color: var(--sidemenu-font-color);
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  transition: all 0.3s ease;
  overflow: hidden;
}

.vulReportContainer h3 {
  margin: 10px;
}

.vulReportContainer p {
  margin-right: 10px;
  margin-left: 10px;
}

.vulReportList {
  margin: 5px 5px 10px 5px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 180px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.vulReportList::-webkit-scrollbar {
  display: none;
}

.vulReportList a {
  color: var(--font-color);
  text-decoration: none;
}

.vulLevel {
  width: 15%;
  margin: 5px;
  padding: 2px;
  text-align: center;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}

.vulLevelHeader {
  width: 15%;
  margin: 5px;
  padding: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row {
  display: flex;
  border-bottom: 1px solid var(--ProtocolPage-border-color);
}

.stickyRow {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--background-color);
  display: flex;
  border-bottom: 1px solid var(--ProtocolPage-border-color);
}

.vulInfo {
  padding: 5px;
  width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.linkIcon {
  padding: 5px;
  width: 40%;
  text-align: right;
}

.alertContainerNoReco {
  padding: 10px;
  border-radius: 10px;
  margin-top: 2rem;
  align-items: center;
  vertical-align: middle;
  display: flex;
  background-color: rgba(75, 192, 192, 0.2);
}

.alertContainerNoReco p {
  margin-left: 10px;
}

.IconNoReco {
  color: rgba(75, 192, 192, 1);
  align-items: center;
  display: flex;
}