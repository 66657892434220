.container {
  border-radius: 10px;
  border-color: var(--ProtocolPage-border-color);
  border-style: solid;
  border-width: var(--ProtocolPage-border-size);
  min-height: 10rem;
  padding: 1rem;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.chartContainer {
  flex-grow: 1;
  width: 25rem;
  height: 15rem;
}

.alertContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.InfoContainer {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.InfoContainerBoxText {
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
}

.InfoContainerBoxReverse {
  background-color: var(--main-background);
  border-radius: 5px;
  padding: 10px;
  color: var(--sidemenu-font-color);
  width: 80%;
}
.InfoContainerBoxReverse a {
  font-size: 16px;
  color: #6e5ee2;
  text-decoration: none;
  cursor: pointer;
}
.adminInfoContainer {
  margin-top: 10px;
  background-color: var(--background-color);
  border-radius: 5px;
  padding: 10px;
  color: var(--sidemenu-font-color);
}

.adminInfoContainerItems {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.adminInfoBoxText {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  align-content: center;
  border-radius: 5px;
  background-color: var(--main-background);
  gap: 10px;
}

.OffchainInfoContainer {
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  color: var(--sidemenu-font-color);
}

.OffchainMaintanceScoreGraph {
  margin-top: 0.25rem;
  margin-left: 20px;
}

.OffchainMaintanceScoreGraphItems {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.OffchainMaintanceScoreGraphItem {
  width: 4rem;
  height: 7px;
  border-radius: 1px;
  border-width: 1px;
  border-style: solid;
  -webkit-box-shadow: 0px 0px 10px 1px var(--backgound-color-dark);
  box-shadow: 0px 0px 10px 1px var(--backgound-color-dark);
}

.OffchainMaintanceScoreGraphItem p {
  margin-top: 8px;
  font-size: 10px;
  font-weight: normal;
}

.InfoContainerBoxReverseOffChainHeader {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.OffchainInfoBoxText {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  align-content: center;
  border-radius: 5px;
  background-color: var(--background-color);
  gap: 10px;
}
