.vulReportContainer h3 {
  margin: 10px;
}
.vulReportContainer p {
  margin-right: 10px;
  margin-left: 10px;
}
.vulReportList {
  margin: 5px 5px 10px 5px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 250px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  flex-wrap: nowrap;
}
.vulReportList::-webkit-scrollbar {
  display: none;
}
.vulReportList a {
  color: var(--font-color);
  text-decoration: none;
}
.vulLevel {
  width: 10%;
  margin: 5px;
  padding: 2px;
  text-align: center;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}
.vulLevelHeader {
  width: 10%;
  margin: 5px;
  padding: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.row {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid var(--ProtocolPage-border-color);
}
.stickyRow {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--bg-color);
  display: flex;
  border-bottom: 1px solid var(--ProtocolPage-border-color);
}
.vulInfo {
  padding: 5px;
  width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.linkIcon {
  padding: 5px;
  width: 40%;
  text-align: right;
}
