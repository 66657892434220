.container {
  border-radius: 10px;
  border-color: var(--ProtocolPage-border-color);
  border-style: solid;
  border-width: var(--ProtocolPage-border-size);
  min-height: 10rem;
  padding: 1rem;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.alertContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.InfoContainer {
  flex-grow: 1;
  width: 50%;
}

.InfoContainerText {
  margin-top: 10px;
  background-color: var(--background-color);
  border-radius: 5px;
  padding: 10px;
  color: var(--sidemenu-font-color);
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}

.InfoContainerText a {
  text-decoration: none;
  color: var(--sidemenu-font-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
}

.NewsContainer {
  margin-top: 10px;
  flex-grow: 1;
  width: 50%;
  border-radius: 5px;
  background-color: var(--background-color);
  padding: 10px;
  color: var(--sidemenu-font-color);
}

.NewsContainer p {
  font-weight: bold;
}

.NewsView {
  margin-top: 10px;
}

.NewsView .row {
  list-style: none;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--sidemenu-font-color);
  height: 2rem;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  flex-direction: row;
}

.NewsView .row .text {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0px;
  font-weight: normal;
  flex-direction: row;
  text-decoration: none;
  color: var(--sidemenu-font-color);
  align-items: center;
  vertical-align: middle;
}

.NewsView .row .lostAmount {
  width: 30%;
  margin-top: 0px;
  font-weight: normal;
}

.NewsView .row .date {
  width: 20%;
  text-align: right;
  font-weight: normal;
  flex-direction: row;
}

.NewsView .row .severityDisplay {
  align-content: center;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
}

.AuditCompanyLogoContainer {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-right: 5rem;
  width: 50%;
}

.AuditCompanyList {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 10px;
}

.AuditCompanyLogoContainer p {
  margin-top: 0px;
}

.AuditCompanyLogo {
  max-height: 1.5rem;
  max-width: 1.5rem;
  border-radius: 50%;
}

.AuditCompany {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 5px;
  max-width: 6.5rem;
  font-size: 11px;
  background-color: var(--background-color);
}

.AuditCompany a {
  margin-left: 10px;
  padding: 0px;
  color: var(--sidemenu-font-color);
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.AuditCompany p {
  margin-left: 10px;
  padding: 0px;
  color: var(--sidemenu-font-color);
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.LowerRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.DeployedChainsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-right: 5rem;
  width: 50%;
  overflow: hidden;
}