.basicList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  color: #fff;
}

.basicList__header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 600;
}

.basicList__removeIconWrapper {
  color: #a78bfa;
  border: 1.5px solid #a78bfa;
  filter: drop-shadow(0 0 8px rgba(167, 139, 250, 0.5));
  background-color: rgba(167, 139, 250, 0.1);
  margin-left: 8px;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basicList__removeIconWrapper:hover {
  background: rgba(255, 255, 255, 0.2);
}

.basicList__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.basicList__item {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.basicList__inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.basicList__input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  background: transparent;
}
