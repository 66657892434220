/* Main Styling Page, define global vars here */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*Define Globle Colors here*/

:root {
  --toastify-color-success: #6e5ee2 !important;
  --backgound-color-dark: #0f141a;
  --lightborder-color: #d6d6d6;
  --font-color: #d6d6d6;
  /* TODO: check if this needs to be defined globally */
  --ProtocolPage-border-color: #d6d6d6;
  --ProtocolPage-border-size: 1px;
  --ProtocolPage-text-color: #d6d6d6;
  --accent-color: #6e5ee2;
  /* Light theme */
  --text-primary: #000000;
}

[data-theme="dark"] {
  --text-primary: #ffffff;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
}

html {
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  flex: 1;
  background-color: var(--main-background);
}

.container {
  max-width: 1200px;
  width: 100%;
}

.react-flow__node-default {
  background-color: var(--background-color) !important;
  color: var(--font-color) !important;
}

.react-flow__controls-button {
  background-color: var(--background-color) !important;
  color: var(--font-color) !important;
  border-color: var(--background-color) !important;
}

.react-flow__controls-button svg {
  color: var(--font-color) !important;
  fill: var(--font-color) !important;
}

@media (max-width: 1150px) {
  .spacer {
    padding-top: 30px;
  }
}

@media (max-width: 768px) {
  .showcase {
    flex-direction: column;
  }
}

@media (max-width: 580px) {
  .publications .information,
  .publications .risks {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .publications .information .icon,
  .publications .risks .icon {
    font-size: 300%;
  }
  .publications h3 {
    text-align: center;
  }
  .reports {
    margin-left: 0 !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  .payment-plan {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .navbar {
    height: 100px;
  }
  .navbar .container {
    flex-direction: column;
    gap: 20px;
  }
  .footer .container {
    flex-direction: column;
    gap: 20px;
    width: 50%;
    margin-left: 30%;
  }
  .footer .social-links {
    display: flex;
    gap: 10px;
  }
  .footer .social-links a {
    font-size: 150%;
  }
}

/* General form styles */

.inputGroup {
  margin-bottom: 15px;
}

.loginLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.loginInput {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--lightborder-color);
  border-radius: 4px;
}

.errorMsg {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

.buttons {
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.primaryButton {
  background-color: var(--accent-color);
  color: white;
}

.rotate-center-edonec {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.rotate-center-edonec.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-center-edonec.up {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
