.error-container {
  width: 100%;
  height: 50vh;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-box {
  height: 5rem;
  width: 25rem;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px rgba(255, 99, 132, 1);
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(255, 99, 132, 0.2);
}

.error-box p {
  margin-left: 10px;
  font-size: 18px;
  color: var(--ProtocolPage-text-color);
}

.icon {
  color: rgba(255, 99, 132, 1);
  align-items: center;
  display: flex;
  height: 3rem;
  width: 3rem;
}
