.vulReportContainer {
  background-color: var(--background-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  color: var(--sidemenu-font-color);
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  transition: all 0.3s ease;
}
.vulReportContainer h3 {
  margin: 10px;
}
.vulReportContainerText {
  margin-right: 10px;
  margin-left: 10px;
}
.vulReportList {
  margin: 5px 5px 10px 5px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 180px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.vulReportList::-webkit-scrollbar {
  display: none;
}
.vulReportList a {
  color: var(--font-color);
  text-decoration: none;
}
.shortListText {
  width: 15%;
  margin: 5px;
  padding: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.row {
  display: flex;
  border-bottom: 1px solid var(--ProtocolPage-border-color);
}
.stickyRow {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--background-color);
  display: flex;
  border-bottom: 1px solid var(--ProtocolPage-border-color);
}
.vulInfo {
  padding: 5px;
  width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.linkIcon {
  padding: 5px;
  width: 40%;
  text-align: right;
}
