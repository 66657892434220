/* Block */
.navbar {
  background-color: var(--background-color);
  height: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  color: #fff;
}

/* Elements */
.navbar__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.navbar__logo {
  width: 141px;
  padding-left: 20px;
}

.navbar__logo-link {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
}

/* Modifier */
.navbar__logo-link--fixed {
  position: fixed;
  top: 38.5px;
  z-index: 5;
}

.navbar__links {
  list-style: none;
  display: flex;
  gap: 20px;
  font-size: 14px;
  flex-direction: row;
  padding-right: 10px;
  height: 100%;
  align-items: center;
}

.navbar__links-item {
  display: flex;
  flex-direction: row;
  height: 90%;
  align-items: center;
}

.navbar__link {
  text-decoration: none;
  color: var(--font-color);
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 2;
  white-space: nowrap;
  justify-content: center;
}
.navbar__link span {
  font-weight: bold;
}
.navbar__link:hover,
.navbar__link--active {
  color: var(--primary-color);
  text-decoration: none;
}

.navbar__link-icon {
  font-size: 18px;
  min-width: 18px;
}

/* Company menu styles */
.navbar__company-menu {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  width: 150px;
  justify-content: center;
  color: var(--font-color);
  transition: all 0.3s ease-in-out;
}

.navbar__company-menu:hover {
  color: var(--primary-color);
}

.navbar__company-menu-link {
  font-size: 12px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 2;
  justify-content: flex-start;
  color: var(--font-color);
}

.navbar__company-menu-link:hover,
.navbar__company-menu-link--active {
  color: var(--primary-color);
}

.navbar__hamburger-icon {
  font-size: 26px;
  color: var(--font-color);
  transition: all 0.3s ease-in-out;
}

.navbar__company-menu:hover .navbar__hamburger-icon {
  color: var(--primary-color);
}
