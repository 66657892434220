.report {
    height: 3.5rem;
    width: 15rem;
    background-color: var(--background-color);
    border-radius: 10px;
    border-width: 1px;
    padding: 10px;
    -webkit-box-shadow:
      0 2px 10px rgba(0, 0, 0, 0.1),
      0 0 1px rgba(255, 255, 255, 0.1);
    border: 1px solid var(--border-color);
    box-shadow:
      0 2px 10px rgba(0, 0, 0, 0.1),
      0 0 1px rgba(255, 255, 255, 0.1);
  }
  
  .report a {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-decoration: none;
    color: var(--font-color);
  }
  
  .logo {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
  .titleContainer {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;
    gap: 10px;
  }
  .titleTextConatainer {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }
  .audits {
    color: var(--font-color);
    font-size: 14px;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
  }
  .auditsNumber {
    background-color: var(--drop-down-menu);
    border-radius: 3px;
    margin-left: 5px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .rating {
    margin-top: 15px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;
  }
  .rating p {
    margin-left: 20px;
    font-weight: bold;
  }
  .report .title {
    color: var(--ProtocolPage-text-color);
    font-weight: 700;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 7rem;
  }
  .vulLevel {
    padding: 2px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
  }