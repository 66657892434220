.holder {
  height: 30px;
  width: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 21px;
  border-radius: 6px;
  color: white;
}
