.searchBarWrapper {
  width: 100%;
  height: 2rem;
  border-radius: 5px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
}
.searchBarWrapper input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1rem;
  width: 100%;
  margin-left: 5px;
  color: var(--font-color);
}
.searchBarWrapper input:focus {
  outline: none;
}
.searchBarWrapper svg {
  color: var(--secondary-text);
}
.searchBarWrapper input::placeholder {
  color: var(--secondary-text);
  opacity: 1; /* Firefox */
}

.searchBarWrapper input::-ms-input-placeholder {
  /* Edge 12-18 */
  color: var(--secondary-text);
}
