.metric-container {
    margin-top: 10px;
    background-color: var(--background-color);
    border-radius: 5px;
    padding: 10px;
    color: var(--sidemenu-font-color);
    width: 100%;
    height: 5rem;
  }
  
  .metric-container p {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
  }