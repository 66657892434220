.stepper {
  width: 100%;
  margin: 20px 0;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.stepper__list {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.stepper__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.stepper__indicator {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #f0f0f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #666;
}

.stepper__indicator--active {
  background-color: #8855ff;
  color: white;
}

.stepper__indicator--completed {
  background-color: #8855ff;
  color: white;
}

.stepper__label {
  font-size: 14px;
  color: #666;
}

.stepper__label--active {
  color: #8855ff;
  font-weight: 500;
}

.stepper__checkmark {
  font-size: 16px;
}

.stepper--dark .stepper__indicator {
  background-color: #2a2a2a;
  color: #999;
}

.stepper--dark .stepper__indicator--active,
.stepper--dark .stepper__indicator--completed {
  background-color: #a280ff;
  color: white;
}

.stepper--dark .stepper__label {
  color: #999;
}

.stepper--dark .stepper__label--active {
  color: #a280ff;
}
