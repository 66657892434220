:root {
  --text-primary: #1a1a1a;
  --text-secondary: white;
}

[data-theme="ligh"] {
  --text-primary: #f5f5f5;
  --text-secondary: white;
}

.wallet-connect-step {
  max-width: 500px;
  margin: 0 auto;
  color: var(--text-primary);
  background-color: var(--container-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow:
    0 2px 10px rgba(0, 0, 0, 0.1),
    0 0 1px rgba(255, 255, 255, 0.1);
  padding: 32px;
}

.wallet-connect-step__title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 12px;
  text-align: left;
  color: var(--text-primary);
}

.wallet-connect-step__description {
  font-size: 14px;
  color: var(--text-secondary);
  line-height: 1.5;
  margin-bottom: 24px;
}

.wallet-connect-step__description--dark {
  color: #a0a0a0;
}

.wallet-connect-step__description--light {
  color: #4a4a4a;
}

.wallet-connect-step__buttons-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;
}

.wallet-connect-step__progress-bar-wrapper {
  margin-bottom: 24px;
}
