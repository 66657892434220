.container {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 1000px;
  color: var(--font-color);
}
.containerInner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  height: 100%;
  position: relative;
}
.header {
  color: var(--font-color);
  margin-top: 20px;
}
.CompanieList {
  border-radius: 5px;
  margin-top: 10px;
  max-height: 30rem;
  overflow: scroll;
  overflow-x: hidden;
}
.CompanieList .row {
  list-style-type: none;
  padding: 15px;
  display: flex;
  flex-direction: row;
  border-bottom: var(--ProtocolPage-border-size) solid var(--background-color);
  align-items: center;
  text-align: left;
}
.CompanieList .stickyRow {
  position: sticky;
  top: 0;
  z-index: 1;
  list-style-type: none;
  padding: 15px;
  display: flex;
  flex-direction: row;
  border-bottom: var(--ProtocolPage-border-size) solid var(--background-color);
  background-color: var(--main-background);
  align-items: center;
  text-align: left;
}
.CompanieList .image {
  max-height: 2rem;
  min-width: 2rem;
}
.CompanieList .row p,
.CompanieList .stickyRow p {
  margin-top: 0px;
  width: 33%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CompanieList .row .rowNameContainer {
  margin-top: 0px;
  width: 20rem;
  align-items: center;
  vertical-align: center;
  display: flex;
  flex-direction: row;
}
.CompanieList .row .rowNameContainer p {
  margin-left: 20px;
}
.CompanieList .row .companyType {
  text-align: right;
}
.CompanieList .row .headerName,
.CompanieList .stickyRow .headerName {
  width: 20rem;
}
