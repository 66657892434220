.button {
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 5px;
  font-size: 16px !important;
  text-decoration: none !important;
  text-transform: none;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.button-link {
  text-decoration: none;
}
.button--primary {
  background-color: var(--primary-color, #9f7aea);
  color: #ffffff;
}
.button--primary:disabled {
  background-color: var(--primary-color, #9f7aea);
  color: #ffffff;
  opacity: 0.5;
}
.button--primary:hover {
  background-color: var(--primary-hover, #8b5cf6);
}

.button--outline {
  background-color: transparent;
  border: 1px solid var(--primary-color, #9f7aea);
  color: var(--primary-color, #9f7aea);
}

.button--outline:hover {
  background-color: var(--primary-color, #9f7aea);
  color: #ffffff;
}

.button--small {
  padding: 8px 12px;
  font-size: 14px !important;
}
.button--tertiary {
  background-color: transparent !important;
  color: var(--text-color) !important;
  border: 1px solid var(--border-color) !important;
  transition: all 0.3s ease;
}
.button--delete {
  width: auto;
  background-color: transparent !important;
  color: rgba(255, 99, 132, 1) !important;
  border: 1px solid rgba(255, 99, 132, 1) !important;
  transition: all 0.3s ease;
}
