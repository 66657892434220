.theme-light {
  --input-bg: #ffffff;
  --input-border-color: #cccccc;
  --input-text: #333333;
  --placeholder-color: #666666;
}

.theme-dark {
  --input-bg: #3a3a4e;
  --input-border-color: #4a4a5e;
  --input-text: #ffffff;
  --placeholder-color: #8e8ea0;
}

.form-field {
  position: relative;
  width: 100%;
}

.form-field__input {
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  resize: vertical;
  min-height: 100px;
}

.theme-light .form-field__input {
  background-color: var(--input-bg);
  border: 1px solid var(--input-border-color);
  color: var(--input-text);
}

.theme-dark .form-field__input {
  background-color: var(--input-bg);
  border: 1px solid var(--input-border-color);
  color: var(--input-text);
}

.form-field--with-icon {
  position: relative;
}

.form-field__icon {
  position: absolute;
  right: 12px;
  top: 12px;
  background: none;
  border: none;
  color: var(--placeholder-color);
  cursor: pointer;
}

.form-field__icon > button {
  background: none;
  border: none;
}

.form-field__label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 600;
}

.theme-dark .form-field__label {
  color: #ffffff;
  opacity: 0.9;
}

.theme-light .form-field__label {
  color: #333333;
  opacity: 0.9;
}
