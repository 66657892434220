.codeComplexityLayout {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  padding-inline: 25px;
}

.codeComplexityColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-block: 25px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 30%;
  float: right;
  padding-bottom: 25px;
}
