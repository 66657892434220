.theme-light {
  --input-bg: #ffffff;
  --input-border-color: #cccccc;
  --input-text: #333333;
  --placeholder-color: #666666;
}

.theme-dark {
  --input-bg: #3a3a4e;
  --input-border-color: #4a4a5e;
  --input-text: #ffffff;
  --placeholder-color: #8e8ea0;
}

.form-radio {
  display: flex;
  gap: 16px;
  padding: 12px 16px;
  background-color: var(--input-bg);
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  transition:
    background-color 0.2s,
    border-color 0.2s,
    color 0.2s;
}

.form-radio__label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--input-text);
}

.form-radio__input {
  margin-right: 8px;
  cursor: pointer;
}

.form-radio__text {
  font-size: 14px;
  color: var(--input-text);
}

.form-radio__label:disabled,
.form-radio__text--disabled {
  color: var(--placeholder-color);
}
