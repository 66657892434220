.container {
  max-width: 1400px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  color: var(--ProtocolPage-text-color);
}
.reportContainer {
  flex-grow: 1;
  margin-left: 30px;
  margin-right: 30px;
  min-width: 50%;
}

.header {
  display: flex;
  margin-top: 60px;
  vertical-align: center;
  align-items: flex-end;
  justify-content: space-between;
}
.header h3 {
  font-size: 24px;
  color: var(--ProtocolPage-text-color);
  min-width: 10rem;
  margin-bottom: 10px;
}
.headerButton {
  display: flex;
  border: none;
  background-color: #6e5ee2;
  color: #fff;
  cursor: pointer;
  width: 8rem;
  height: 1.5rem;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-size: 13px;
  align-items: center;
  justify-content: center;
}
.CompanieList {
  border-radius: 5px;
  margin-top: 10px;
}
.CompanieList .row {
  list-style-type: none;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  border-bottom: var(--ProtocolPage-border-size) solid var(--background-color);
  align-items: center;
  text-align: left;
}
.CompanieList .image {
  max-height: 2rem;
  border-radius: 5px;
}
.CompanieList .row p {
  margin-top: 0px;
  width: 33%;
  text-align: right;
}
.CompanieList .row .rowNameContainer {
  margin-top: 0px;
  width: 33%;
  align-items: center;
  vertical-align: center;
  display: flex;
  flex-direction: row;
}
.CompanieList .row .rowNameContainer p {
  margin-left: 20px;
  white-space: nowrap;
}
.rating {
  font-size: 20px;
  width: 33%;
  vertical-align: center;
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.CompanieList .row a {
  margin-top: 0px;
  width: 33%;
}

.CompanieList a {
  text-decoration: none;
  color: var(--font-color);
}
.Search {
  display: flex;
  flex-direction: row;
  height: 3rem;
  align-items: center;
  gap: 10px;
}
.Search button {
  width: 9rem;
}
.filerRow{
  display: flex;
  flex-direction: row;
  height: 34px;
  gap: 10px;
}
.filerRow button {
  width: fit-content;
  color: var(--ProtocolPage-text-color) !important;
  text-wrap: nowrap;
  transition: transform 2s;
}
.filerRow button:disabled{
  color: var(--background-color) !important;
}
.filerRow div {
  width: fit-content;
  color: var(--ProtocolPage-text-color) !important;
  text-wrap: nowrap;
}
