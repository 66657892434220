.lastBox {
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
}
.lastBox svg {
  font-size: 16px;
}
.verifiedToolTip {
  background-color: var(--drop-down-menu);
  position: absolute;
  top: 60%;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}
.triangle {
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid var(--drop-down-menu);
  left: 50%;
  transform: translateX(-50%);
}
.verifiedToolTip a {
  color: var(--accent-color);
}
.verifiedToolTip h2 {
  font-size: 14px;
}
