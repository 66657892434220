.theme-light {
  --input-bg: #ffffff;
  --input-border-color: #cccccc;
  --input-text: #333333;
  --placeholder-color: #666666;
}

.theme-dark {
  --input-bg: #3a3a4e;
  --input-border-color: #4a4a5e;
  --input-text: #ffffff;
  --placeholder-color: #8e8ea0;
}

.form-select {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  font-size: 14px;
  background-color: var(--input-bg);
  color: var(--input-text);
  cursor: pointer;
  transition:
    background-color 0.2s,
    border-color 0.2s,
    color 0.2s;
}

.form-select:focus {
  outline: none;
  border-color: #805ad5;
}

/* Style the placeholder option */
.form-select option[value=""] {
  color: var(--placeholder-color);
}

/* Ensure dark options in dropdown */
.form-select option {
  background-color: var(--input-bg);
  color: var(--input-text);
  padding: 12px 16px;
}

.form-field {
  width: 100%;
}

.form-field__label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 600;
}

.theme-dark .form-field__label {
  color: #ffffff;
  opacity: 0.9;
}

.theme-light .form-field__label {
  color: #333333;
  opacity: 0.9;
}
