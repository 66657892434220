.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  color: var(--sidemenu-font-color);
}
.splitContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
}
