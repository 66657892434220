.form-field {
  position: relative;
}

.form-field__password-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.form-field__input {
  padding-right: 48px !important;
}
