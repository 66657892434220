.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerContainer {
  padding-top: 100px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.innerContainer button {
  padding: 8px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: #6e5ee2;
  cursor: pointer;
}
.error {
  color: red;
  padding: 10px;
  width: 80%;
  text-align: center;
}
