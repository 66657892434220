.buttonsContainer {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.buttonsContainer button {
  height: 27px;
  width: 22px;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  background-color: var(--background-color);
  color: var(--font-color);
}
.buttonsContainer button:disabled {
  height: 27px;
  width: 22px;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  background-color: var(--background-color);
  color: var(--font-color);
}
