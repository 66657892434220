.reports {
  display: flex;
  flex-direction: column;
}
.Carousel_Container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: calc(45rem + 30px);
}
.Carousel_track {
  display: flex;
  width: 100%;
  gap: 15px;
  animation: slide 20s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(0);
  } /* Pause before moving */
  40% {
    transform: translateX(calc(-100% - 15px));
  }
  60% {
    transform: translateX(calc(-100% - 15px));
  } /* Pause on second slide */
  80% {
    transform: translateX(calc(-200% - 15px));
  }
  100% {
    transform: translateX(0);
  } /* Loop back smoothly */
}
