/* Payment page styles */

.pre-onboarding__container {
  max-width: 1200px;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
}

.pre-onboarding__container h3 {
  color: var(--font-color);
}

.pre-onboarding__inner-container {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  max-height: 800px;
  align-self: center;
}

.pre-onboarding__get-pro-container {
  --bg-color: #ffffff;
  --text-color: #333333;
  --description-color: #666666;
  background-color: var(--bg-color);
  width: 360px;
  border-radius: 5px;
  padding: 24px;
  margin: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--text-color);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/* Pro plan specific styles - removed border, added gradient and enhanced shadow */
.pre-onboarding__get-pro-container--pro {
  background: linear-gradient(145deg, #2b2b3d, #363654);
  transform: translateY(-10px);
  box-shadow: 
    0 20px 30px rgba(159, 122, 234, 0.2),
    0 0 40px rgba(159, 122, 234, 0.1);
  position: relative;
  z-index: 1;
  --text-color: #ffffff;
  --description-color: #e2e2e2;
}

/* Dark theme overrides */
:global(.dark-theme) .pre-onboarding__get-pro-container {
  --bg-color: #2b2b3d;
  --text-color: #ffffff;
  --description-color: #a0a0b0;
}

:global(.dark-theme) .pre-onboarding__get-pro-container--pro {
  background: linear-gradient(145deg, #363654, #4a4a6a);
  box-shadow: 
    0 15px 20px rgba(159, 122, 234, 0.07),
    0 0 30px rgba(159, 122, 234, 0.05);
}

.pre-onboarding__get-pro-container h3 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 12px;
  color: var(--text-color);
}

.pre-onboarding__description {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 14px;
  color: var(--description-color);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 15px;
}

.pre-onboarding__feature-list {
  list-style: none;
  margin-bottom: 24px;
  flex-grow: 1;
}

.pre-onboarding__feature-list-item {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--text-color);
  font-size: 14px;
}

.pre-onboarding__feature-list-item svg {
  color: #9f7aea;
  font-size: 16px;
}

.pre-onboarding__pricing {
  font-size: 16px;
  color: var(--description-color);
  margin-top: 20px;
  margin-bottom: 20px;
}

.pre-onboarding__grey-text {
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
  color: var(--description-color);
}

.pre-onboarding__grey-text a {
  color: #9f7aea;
  text-decoration: none;
}

.pre-onboarding__button-container {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.pre-onboarding__forgot {
  margin-top: 10px;
  color: var(--font-color);
  cursor: pointer;
  text-decoration: underline;
  font-size: 13px;
}

.pre-onboarding__user-info-container {
  display: flex;
  flex-direction: column;
  color: var(--font-color);
  width: 100%;
  align-items: flex-start;
  gap: 5px;
}