.payment-page {
  max-width: 500px;
  margin: 0 auto;
  color: #ffffff;
  background-color: var(--container-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow:
    0 2px 10px rgba(0, 0, 0, 0.1),
    0 0 1px rgba(255, 255, 255, 0.1);
  padding: 32px;
}

.payment-page__title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 12px;
  text-align: left;
  color: #000000;
}

.payment-page--dark .payment-page__title {
  color: #ffffff;
}

.payment-page--light .payment-page__title {
  color: #000000;
}

.payment-page__description {
  font-size: 14px;
  color: #e0e0e0;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.payment-page--dark .payment-page__description {
  color: #a0a0b0;
}

.payment-page--light .payment-page__description {
  color: #a0a0b0;
}

.payment-page__link {
  color: #9f7aea;
  text-decoration: none;
  font-weight: 500;
}

.payment-page__link:hover {
  text-decoration: underline;
}

.payment-page__support-text {
  color: #a0a0b0;
  font-size: 14px;
  margin-bottom: 24px;
}

.payment-page__button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.payment-page__progress-bar-wrapper {
  margin-bottom: 24px;
}

.payment-page__input {
  width: 100%;
  padding: 12px 16px;
  background-color: #3a3a4e;
  border: 1px solid #4a4a5e;
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 16px;
}

.payment-page__input::placeholder {
  color: #8e8ea0;
}
