.logo {
  width: 3rem;
  height: 3rem;
  margin: 5px;
  padding: 2px;
  text-align: center;
  border-radius: 12px;
}
.text {
  width: 100%;
  margin: 5px;
  padding: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.IDtext {
  margin: 5px;
  padding: 2px;
  width: 15%;
}
.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  border-bottom: 1px solid var(--background-color);
}
.Search {
  display: flex;
  flex-direction: row;
  height: 3rem;
  align-items: center;
  gap: 10px;
}
.Search button {
  width: 9rem;
}
.header {
  color: var(--ProtocolPage-text-color);
  width: 100%;
  border-bottom: solid 2px var(--background-color);
  padding-top: 26px;
  padding-bottom: 26px;
  padding-right: 32px;
  padding-left: 32px;
  position: sticky;
  top: 0;
  z-index: 5;
  background: var(--main-background);
}
.alertContainer {
  padding: 10px;
  border-radius: 10px;
  margin: 32px;
  align-items: center;
  vertical-align: middle;
  display: flex;
  background-color: rgba(255, 206, 86, 0.2);
  border: 1px solid rgba(255, 206, 86, 1);
}
.icon {
  color: rgba(255, 206, 86, 1);
  align-items: center;
  display: flex;
  height: 3rem;
  width: 3rem;
}
