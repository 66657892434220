.container {
  border-radius: 10px;
  background-color: var(--background-color);
  min-height: 6rem;
  padding: 1rem;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}
.container p {
  margin-top: 10px;
}
.container a {
  color: var(--font-color);
  margin-left: 5px;
}
