.container {
  border-radius: 10px;
  border-color: var(--ProtocolPage-border-color);
  border-style: solid;
  border-width: var(--ProtocolPage-border-size);
  min-height: 10rem;
  padding: 1rem;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}
.InfoContainer {
  flex-grow: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 1rem;
}
.row p a,
.stickyRow p a {
  color: var(--font-color);
  text-decoration: none;
}
.flowChartContainer {
  height: 15rem;
  width: 100%;
}
.dependecyGraphContainer {
  transition: all 0.3s ease;
  overflow: hidden;
  margin: 10px;
}
.vulReportContainer {
  display: flex;
  flex-direction: column;
  color: var(--sidemenu-font-color);
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  transition: all 0.3s ease;
  overflow: hidden;
}

.vulReportContainer h3 {
  margin: 10px;
}
.vulReportList {
  margin: 5px 5px 10px 5px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 230px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.vulReportList::-webkit-scrollbar {
  display: none;
}
.vulReportList a {
  color: var(--font-color);
  text-decoration: none;
}
.vulLevel {
  width: 15%;
  margin: 5px;
  padding: 2px;
  text-align: center;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}
.vulLevelHeader {
  width: 15%;
  margin: 5px;
  padding: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vulReportList .row {
  display: flex;
  border-bottom: 1px solid var(--ProtocolPage-border-color);
}
.vulReportList .stickyRow {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  border-bottom: 1px solid var(--ProtocolPage-border-color);
  background-color: var(--bg-color);
}
.codeInfo {
  padding: 5px;
  width: 17.5%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}
.codeInfoFileName {
  padding: 5px;
  width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.linkIcon {
  padding: 5px;
  width: 40%;
  text-align: right;
}
.chartContainer {
  width: 30%;
  height: 16rem;
}
.codeBaseLink{
  text-decoration: none;
  color: var(--sidemenu-font-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-top: 15px;
}