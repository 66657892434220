@keyframes gradientGlow {
  0% {
    border-color: rgba(159, 122, 234, 0.4);
    background-position: 0% 50%;
  }
  50% {
    border-color: rgba(159, 122, 234, 0.6);
    background-position: 50% 50%;
  }
  100% {
    border-color: rgba(159, 122, 234, 0.4);
    background-position: 0% 50%;
  }
}

.featureBox {
  width: 35%;
  margin-top: 60px;
}
.featuredBoxInner {
  height: 34.5rem;
  -webkit-box-shadow:
    0 2px 10px rgba(0, 0, 0, 0.1),
    0 0 1px rgba(255, 255, 255, 0.1);
  border: 1px solid var(--border-color);
  box-shadow:
    0 2px 10px rgba(0, 0, 0, 0.1),
    0 0 1px rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: var(--background-color);
}
.featureBox .headerRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.featureBox .headerRow .image {
  max-height: 3rem;
  border-radius: 50%;
}
h4 {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.companyTypeRow {
  padding: 10px;
  border-radius: 5px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  border: #9f7aea solid 1px;
  background: linear-gradient(
    135deg,
    rgba(159, 122, 234, 0.4) 0%,
    rgba(159, 122, 234, 0.1) 50%,
    rgba(37, 99, 235, 0.01) 100%
  );
  background-size: 200% 200%;
  animation: gradientGlow 6s ease-in-out infinite;

}
.featureBox .trustedBy {
  height: 50%;
  margin-top: 2rem;
}

.featureBox .companyBox {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  background-color: var(--main-background);
  padding: 5px;
  align-items: center;
  gap: 1rem;
  margin-top: 0.2rem;
}
.featureBox .companyBox p {
  font-weight: bold;
}
.companyBox .image {
  max-height: 2rem;
  border-radius: 50%;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.dataField {
  background-color: var(--background-color);
  border-radius: 10px;
  border-width: 1px;
  padding: 10px;
  border: 1px solid var(--border-color);
  flex-direction: row;
  display: flex;
  margin-top: 1rem;
}