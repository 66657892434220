:root {
  --bg-color: #ffffff;
  --text-color: #333333;
  --subtitle-color: #666666;
  --primary-color: #9f7aea;
  --primary-hover: #8b5cf6;
  --checkbox-bg: #f0f0f0;
  --checkbox-border: #cccccc;
  --error-color: #e53e3e;
  --checkbox-check-color: #ffffff;
  --icon-color: #666666;
}

.form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 32px;
  color: var(--text-color);
  background-color: var(--bg-color);
  border: 1px solid var(--input-border);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color);
}

.form--dark {
  --bg-color: #2b2b3d;
  --text-color: #ffffff;
  --subtitle-color: #a0a0b0;
  --checkbox-bg: #3a3a4e;
  --checkbox-border: #4a4a5e;
  --checkbox-check-color: #ffffff;
  --icon-color: #a0a0b0;
}

.form-header {
  margin-bottom: 24px;
}

.form-header__title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 12px;
  color: var(--text-color);
}

.form-header__subtitle {
  font-size: 14px;
  color: var(--subtitle-color);
}

.form-header__link {
  color: var(--primary-color);
  text-decoration: none;
}

.form-field {
  margin-bottom: 16px;
}

.form-field__input {
  width: 100%;
  padding: 12px 16px;
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: 5px;
  color: var(--input-text);
  font-size: 14px;
}

.form-field__input::placeholder {
  color: var(--placeholder-color);
}

.form-field--with-icon {
  position: relative;
}

.form-field__icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--icon-color);
  cursor: pointer;
}

.form-error {
  color: var(--error-color);
  font-size: 14px;
  margin-bottom: 16px;
}

.form-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 10px;
}

.form-checkbox__input {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background-color: var(--checkbox-bg);
  border: 1px solid var(--checkbox-border);
  border-radius: 5px;
  appearance: none;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.form-checkbox__input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.form-checkbox__input:checked::after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--checkbox-check-color);
  font-size: 12px;
  line-height: 1;
}

.form-checkbox__label {
  font-size: 14px;
  color: var(--subtitle-color);
  user-select: none;
}

.form-row {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  width: 100%;
}

.form-row__item {
  flex: 1;
  min-width: 0;
  margin: 0 !important;
}
