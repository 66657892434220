/* Block: account */
.account {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding: 2rem 0;
}

.account__container {
  max-width: 1200px;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  color: var(--text-color);
}

.account__main {
  width: calc(100% - 325px);
  min-height: 25rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  background-color: var(--container-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.account__title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--font-color);
}

.account__subtitle {
  color: var(--secondary-text);
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

/* Block: info */
.info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 25px;
}

.info-section {
  margin-bottom: 25px;
}

.info-section__title {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #8e8e8e;
}

.info-section__placeholder {
  color: var(--secondary-text);
  font-style: italic;
}

/* Block: api-key */
.api-key {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.api-key__text {
  font-family: monospace;
  font-size: 14px;
}

.api-key__copy-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  padding: 8px 0;
  font-size: 14px;
  transition: opacity 0.2s;
}

/* Block: progress */
.progress__container {
  padding-top: 10px;
  display: flex;
  gap:10px;
  white-space: nowrap;
  align-items: center;
  font-size: 14px;
  color: var(--secondary-text);
}

/* Block: button */
.button-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.button-link {
  text-decoration: none;
}

/* Block: alerts */
.alerts {
  width: 300px;
}

.alert {
  background-color: var(--container-bg);
  border-radius: 8px;
  padding: 1rem 1.25rem;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  color: var(--alert-text-color);
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-decoration: none;
}

.alert:hover {
  border: 1px solid #5a4cb9;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.alert__icon {
  color: #ffd700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  width: 2.25rem;
  background: rgba(255, 215, 0, 0.12);
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  margin-right: 24px;
}
.account__alter__subtitle {
  color: var(--secondary-text);
  font-size: 0.8rem;
  margin-bottom: 1.5rem;
}
/* Block: login */
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 4rem 0;
}
