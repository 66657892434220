.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
  }
  .reportContainer {
    flex-grow: 1;
    margin-left: 30px;
    margin-right: 30px;
    min-width: 50%;
  }
  .globalStatsContainer {
    flex-grow: 1;
  }
  .publications {
    color: var(--ProtocolPage-text-color);
  }
  
  .publications h3 {
    margin-top: 60px;
    font-size: 24px;
    color: var(--ProtocolPage-text-color);
  }
  .infoText {
    margin-top: 10px;
    color: var(--ProtocolPage-text-color);
  }
  .CompanieList {
    border-radius: 5px;
    margin-top: 10px;
  }
  .CompanieList .row {
    list-style-type: none;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    border-bottom: var(--ProtocolPage-border-size) solid var(--background-color);
    align-items: center;
    text-align: left;
  }
  .CompanieList .image {
    max-height: 3rem;
    min-width: 3rem;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
  }
  .CompanieList .row p {
    margin-top: 0px;
    width: 80%;
  }
  .CompanieList .row .rowNameContainer {
    margin-top: 0px;
    width: 80%;
    align-items: center;
    vertical-align: center;
    display: flex;
    flex-direction: row;
    text-decoration: none;
  }
  .CompanieList .row .rowNameContainer p {
    margin-left: 20px;
  }
  .CompanieList .row a {
    text-decoration: none;
    color: var(--font-color);
  }
  .CompanieList .row .companyType {
    width: 10%;
    text-align: right;
  }
  .vulLevel {
    padding: 2px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    margin-left: auto;
    margin-right: auto;
  }
  .vulnerabilityReport__description {
    font-size: 14px;
    color: var(--secondary-text);
  }