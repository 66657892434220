.progress-bar {
  --bg-color: #f0f0f0;
  --shadow-color: rgba(0, 0, 0, 0.1);
  height: 10px;
  width: 100%;
  background-color: var(--bg-color);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: inset 0 1px 3px var(--shadow-color);
}

.progress-bar.darkTheme {
  --bg-color: #3a3a4e;
  --shadow-color: rgba(0, 0, 0, 0.2);
}

.progress-bar__filler {
  height: 100%;
  border-radius: inherit;
  background-color: #9f7aea;
  transition: width 0.3s ease-in-out;
  position: relative;
}

.progress-bar__label {
  padding: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

/* Add a gradient effect to the filler */

.progress-bar__filler::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #9f7aea, #8b5cf6);
  opacity: 0.7;
}

/* Add a subtle shine effect */

.progress-bar__filler::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), transparent);
}
