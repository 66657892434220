.side-menu {
  min-width: 250px;
  max-width: 300px;
  position: relative;
  transition: all 0.2s ease;
}

/* Dark theme styles */
.side-menu--dark {
  background-color: var(--background-color);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

/* Light theme styles */
.side-menu--light {
  background-color: rgba(255, 255, 255, 0.95);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.side-menu__list {
  width: 100%;
  position: sticky;
  top: 20px;
  padding: 16px 0;
}

.side-menu__row {
  width: 100%;
  height: 48px;
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0 24px;
  gap: 12px;
  transition: all 0.2s ease;
}

.side-menu__text {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  flex-grow: 1;
}

/* Text colors for different themes */
.side-menu--dark .side-menu__text {
  color: rgba(255, 255, 255, 0.87);
}

.side-menu--light .side-menu__text {
  color: rgba(0, 0, 0, 0.87);
}

.side-menu__button {
  width: calc(100% - 16px);
  height: 48px;
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0 24px;
  gap: 12px;
  transition: all 0.2s ease;
  border-radius: 8px;
  margin: 0 8px;
}

/* Button hover states for different themes */
.side-menu--dark .side-menu__button:hover {
  background-color: rgba(99, 102, 241, 0.1);
  cursor: pointer;
}

.side-menu--light .side-menu__button:hover {
  background-color: rgba(99, 102, 241, 0.08);
  cursor: pointer;
}

.side-menu__logo {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  object-fit: cover;
  position: relative;
  z-index: 2;
}

/* Logo styles for different themes */
.side-menu--dark .side-menu__logo {
  box-shadow: 0 0 15px 2px rgba(99, 102, 241, 0.15);
  background-color: rgba(99, 102, 241, 0.1);
  border: 1px solid rgba(99, 102, 241, 0.2);
}

.side-menu--light .side-menu__logo {
  box-shadow: 0 0 15px 2px rgba(99, 102, 241, 0.15);
  background-color: rgba(99, 102, 241, 0.05);
  border: 1px solid rgba(99, 102, 241, 0.15);
}

.side-menu__logo--temporary {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  filter: grayscale(100%);
  opacity: 0.7;
  position: relative;
  z-index: 2;
}

/* Temporary logo styles for different themes */
.side-menu--dark .side-menu__logo--temporary {
  box-shadow: 0 0 25px 4px rgba(99, 102, 241, 0.15);
  background-color: rgba(99, 102, 241, 0.05);
  border: 1px solid rgba(99, 102, 241, 0.1);
}

.side-menu--light .side-menu__logo--temporary {
  box-shadow: 0 0 25px 4px rgba(99, 102, 241, 0.1);
  background-color: rgba(99, 102, 241, 0.03);
  border: 1px solid rgba(99, 102, 241, 0.08);
}

.side-menu__header-text {
  display: flex;
  align-items: center;
  margin-left: 12px;
  font-size: 16px;
  font-weight: 600;
}

/* Header text colors for different themes */
.side-menu--dark .side-menu__header-text {
  color: rgba(255, 255, 255, 0.87);
}

.side-menu--light .side-menu__header-text {
  color: rgba(0, 0, 0, 0.87);
}
