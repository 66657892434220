.protocol {
  display: flex;
  flex-direction: row;
  z-index: 1;
  width: 100%;
  position: relative;
  height: 100%;
}
.protocol__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  width: 100%;
  position: relative;
  height: 100%;
  flex: 1;
}
.protocol__main {
  margin-top: 40px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  height: 100%;
  width: 100%;
  max-width: 860px;
}
.protocol__header {
  color: var(--ProtocolPage-text-color);
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: solid 2px var(--background-color);
  padding: 10px;
  position: sticky;
  top: 0;
  z-index: 5;
  background: var(--main-background);
  text-align: center;
  align-items: center;
  max-height: 4rem;
  min-height: 4rem;
}
.progress-step {
  text-align: center;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.progress-step__divider {
  width: 3rem;
  border: 2px solid #999999;
}
.progress-step__number {
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  border: 2px solid #6e5ee2;
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
}
.saveButton {
  display: flex;
  border: none;
  background-color: #6e5ee2;
  color: #fff;
  cursor: pointer;
  width: 60rem;
  height: 3rem;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
  margin-right: 10px;
}
button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.container span {
  font-size: 20px;
  color: var(--ProtocolPage-text-color);
}
.alertContainer {
  padding: 10px;
  border-radius: 10px;
  margin: 32px;
  gap: 10px;
  align-items: center;
  vertical-align: middle;
  display: flex;
  background-color: rgba(255, 206, 86, 0.2);
  border: 1px solid rgba(255, 206, 86, 1);
  color: var(--ProtocolPage-text-color);
}
.alertContainer button{
  margin-top: 10px;
}
.icon {
  color: rgba(255, 206, 86, 1);
  align-items: center;
  display: flex;
  height: 3rem;
  width: 3rem;
}