.contentContainer {
  display: flex;
  flex-direction: row;
}

.container {
  width: 100%;
  position: relative;
  color: var(--ProtocolPage-text-color);
}

.protocolContent {
  width: 85%;
  position: relative;
  margin-bottom: 50px;
}
