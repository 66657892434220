.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
}
.reportContainer {
  flex-grow: 1;
  margin-left: 30px;
  margin-right: 30px;
  min-width: 50%;
}
.globalStatsContainer {
  flex-grow: 1;
}
.publications {
  color: var(--ProtocolPage-text-color);
}

.publications h3 {
  margin-top: 60px;
  font-size: 24px;
  color: var(--ProtocolPage-text-color);
}
.infoText {
  margin-top: 10px;
  color: var(--ProtocolPage-text-color);
}
.CompanieList {
  border-radius: 5px;
  margin-top: 10px;
}
.CompanieList .row {
  list-style-type: none;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  border-bottom: var(--ProtocolPage-border-size) solid var(--background-color);
  align-items: center;
  text-align: left;
}
.CompanieList .image {
  max-height: 2rem;
  min-width: 2rem;
  border-radius: 5px;
}
.CompanieList .row p {
  margin-top: 0px;
  width: 33%;
}
.CompanieList .row .rowNameContainer {
  margin-top: 0px;
  width: 33%;
  align-items: center;
  vertical-align: center;
  display: flex;
  flex-direction: row;
}
.CompanieList .row .rowNameContainer p {
  margin-left: 20px;
}
.CompanieList .row a {
  margin-top: 0px;
  width: 33%;
  font-size: 16px;
  color: #6e5ee2;
  text-decoration: none;
  cursor: pointer;
}
.CompanieList .row .companyType {
  text-align: right;
}
