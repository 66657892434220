@keyframes gradientGlow {
  0% {
    box-shadow: 0 0 20px rgba(37, 99, 235, 0.1);
    border-color: rgba(37, 99, 235, 0.4);
    background-position: 0% 50%;
  }
  50% {
    box-shadow: 0 0 30px rgba(37, 99, 235, 0.2);
    border-color: rgba(37, 99, 235, 0.6);
    background-position: 100% 50%;
  }
  100% {
    box-shadow: 0 0 20px rgba(37, 99, 235, 0.1);
    border-color: rgba(37, 99, 235, 0.4);
    background-position: 0% 50%;
  }
}

.onboardingWorkflow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 6rem;
}

.onboardingWorkflow__inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.onboardingWorkflow__gradient {
  position: relative;
  width: 300px;
  height: 550px;
  z-index: 1;
  border-radius: 5px;
  border: 1px solid rgba(37, 99, 235, 0.4);
  background: linear-gradient(
    135deg,
    rgba(37, 99, 235, 0.8) 0%,
    rgba(37, 99, 235, 0.4) 50%,
    rgba(37, 99, 235, 0.1) 100%
  );
  background-size: 200% 200%;
  animation: gradientGlow 6s ease-in-out infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboardingWorkflow__logo {
  max-width: 50%;
  height: auto;
  object-fit: contain;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.onboardingWorkflow__logo:hover {
  opacity: 1;
}

.onboardingWorkflow__steps {
  align-self: flex-start;
}

.onboardingWorkflow__inner--dark {
  --bg-color: #2b2b3d;
  --text-color: #ffffff;
  --subtitle-color: #a0a0b0;
  --checkbox-bg: #3a3a4e;
  --checkbox-border: #4a4a5e;
  --checkbox-check-color: #ffffff;
  --icon-color: #a0a0b0;
}

.onboardingWorkflow__error {
  color: #ff6384;
  font-size: 12px;
  padding: 1px;
  text-align: center;
}

.onboardingWorkflow__description {
  margin: 10px 0;
  padding-bottom: 10px;
  font-size: 14px;
  color: var(--subtitle-color);
  border-bottom: 1px solid var(--border-color);
}

.onboardingWorkflow__form-group {
  margin-bottom: 15px;
  width: 100%;
}

.onboardingWorkflow__label {
  display: block;
  margin-bottom: 5px;
  color: var(--text-color);
  font-size: 14px;
}

.onboardingWorkflow__input {
  width: 100%;
  padding: 12px 16px;
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: 5px;
  color: var(--input-text);
  font-size: 14px;
}

.onboardingWorkflow__button {
  border-radius: 5px;
}

.onboardingWorkflow__button:hover {
  background-color: var(--primary-hover);
}

.onboardingWorkflow__buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
  margin: 10px 0;
}

.onboardingWorkflow__progress {
  scale: 1.4;
}

.onboardingWorkflow__separator {
  height: 1px;
  background-color: #e5e7eb;
  width: 100%;
  margin: 15px 0;
}

.onboardingWorkflow__inner--dark .onboardingWorkflow__separator {
  background-color: #374151;
}

.onboardingWorkflow__step-indicator {
  color: #666;
  font-size: 14px;
  margin: 15px 0;
  align-self: flex-start;
}

.onboardingWorkflow__inner--dark .onboardingWorkflow__step-indicator {
  color: #9ca3af;
}

.onboardingWorkflow__backArrow {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1.2rem;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: var(--bg-color);
  border: 1px solid rgba(37, 99, 235, 0.4);
  border-radius: 5px;
}

.onboardingWorkflow__backArrow:hover {
  color: var(--primary-color);
  border-color: rgba(37, 99, 235, 0.6);
  box-shadow: 0 0 20px rgba(37, 99, 235, 0.1);
}

.onboardingWorkflow__content {
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.onboardingWorkflow__steps {
  width: 100%;
  max-width: 400px;
}
