.topbar {
  height: 80px;
  flex-direction: row;
  display: flex;
  padding-left: 10px;
  padding-top: 5px;
  align-items: center;
  border-color: #fff;
  border-bottom: 1px solid;
  gap: 15px;
  width: 100%;
  position: relative;
  z-index: 5;
}
.topbarLogo {
  max-width: 45px;
  max-height: 45px;
  margin-left: 10px;
  border-radius: 50%;
}
.topbarTitle {
  padding-left: 10px;
  font-weight: 350;
  font-size: 22px;
  display: flex;
  gap: 10px;
  align-items: center;
  white-space: noWrap;
}
.stickyTopBar {
  position: sticky;
  top: 0;
  height: 80px;
  background-color: var(--main-background);
  flex-direction: row;
  display: flex;
  padding-left: 10px;
  padding-top: 5px;
  align-items: center;
  border-color: #fff;
  border-bottom: 1px solid;
  gap: 15px;
  width: 100%;
  z-index: 5;
}
.topbarSocialLinks {
  display: flex;
  gap: 15px;
  font-size: 18px;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  margin-right: 50px;
}
.topbarSocialLinks a {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: var(--font-color);
}
.topbarSocialLinks a:hover {
  color: #6e5ee2;
}
.ratingContainer {
  position: relative;
}
.category {
  background-color: var(--background-color);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 12px;
  border-radius: 6px;
  color: var(--sidemenu-font-color);
}
