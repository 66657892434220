/* Block: account */
.vulnerabilityReport {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding: 2rem 0;
}

.vulnerabilityReport__container {
  max-width: 1200px;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  color: var(--text-color);
}

.vulnerabilityReport__main {
  width: calc(100% - 325px);
  min-height: 25rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;
  background-color: var(--container-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.vulnerabilityReport__main h2 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.splitContainer{
  flex-direction: row;
  display: flex;
  gap: 2rem;
}
.line{
  margin-top: 1rem;
  border-top: 1px solid var(--font-color);
}
.vulnerabilityReport__title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--font-color);
}
.vulnerabilityReport__text {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  color: var(--font-color);
}
.vulLevel {
  padding: 2px;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}
.logo {
  max-height: 3.5rem;
  min-width: 3.5rem;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}