.circular_progress {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
}

.circular_progress__spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
