.stepper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  justify-content: center;
}

.stepper__item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.stepper__number {
  width: 24px;
  height: 24px;
  border: 1.5px solid #4b5563;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4b5563;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  background-color: rgba(255, 255, 255, 0.05);
}

.stepper__number--active {
  color: #a78bfa;
  border-color: #a78bfa;
  filter: drop-shadow(0 0 8px rgba(167, 139, 250, 0.5));
  background-color: rgba(167, 139, 250, 0.1);
}

.stepper__label {
  color: #6b7280;
  font-weight: 400;
  font-size: 14px;
  transition: color 0.2s ease-in-out;
}

.stepper__label--active {
  color: #a78bfa;
}

.stepper__divider {
  width: 32px;
  height: 1px;
  background-color: #4b5563;
  margin: 0 4px;
}
