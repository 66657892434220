.container {
  color: var(--ProtocolPage-text-color);
  display: flex;
  justify-content: center;
  max-width: 1400px;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}
.publications{
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 60px;
  display: flex;
  gap: 3%;
  flex-grow: 1;
}
.reportContainer {
  width: 65%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}
.reports {
  display: flex;
  gap: 15px;
}
.container h3 {
  font-size: 24px;
  color: var(--ProtocolPage-text-color);
  display: flex;
  gap: 6px;
  align-items: flex-end;
}
h3 a {
  font-size: 16px;
  color: #6e5ee2;
  text-decoration: none;
  cursor: pointer;
}
.moreLink {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 1px;
}
.moreLink svg {
  transform: scale(0.9);
}
